"use client";

import { CssBaseline, ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";
import { nbNO } from "@mui/x-date-pickers/locales";

export const orange = {
  15: "#FDFAF5",
  70: "#FED6B3",
  100: "#EA730B",
  200: "#DF7C2B",
};

export const tan = {
  50: "#DBD2C8",
  100: "#FFF7EC",
  150: "#807467",
  300: "#4E4234",
};

export const grey = {
  100: "#FFFFFF",
  150: "#cec5c5",
  300: "#000000",
};

const yellow = {
  100: "#F4B656",
};

declare module "@mui/material/styles" {
  interface Palette {
    orange: Palette["primary"];
    tan: Palette["primary"];
    white: Palette["primary"];
  }

  interface PaletteOptions {
    orange?: PaletteOptions["primary"];
    tan?: Palette["primary"];
    yellow?: Palette["primary"];
    white?: Palette["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    orange: true;
    tan: true;
    white: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsColorOverrides {
    orange: true;
    tan: true;
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    orange: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    orange: true;
    white: true;
    tan: true;
    yellow: true;
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    orange: true;
  }
}

declare module "@mui/material/Tabs" {
  interface TabsPropsIndicatorColorOverrides {
    orange: true;
  }
}
let lightTheme;

lightTheme = createTheme(
  {
    components: {
      MuiStepLabel: {
        styleOverrides: {
          root: {
            "& .Mui-active": {
              color: tan[300],
            },
            "& .Mui-completed": {
              color: tan[300],
            },
          },
        },
      },
      MuiList: { styleOverrides: { root: { backgroundColor: grey[100] } } },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${orange[100]}`,
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            color: tan[300],
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 400,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            borderRadius: "20px",
            color: "red",
            "& .MuiSwipeableDrawer-paperAnchorBottom": {
              color: "red",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 600,
            paddingRight: "50px",
            color: tan[300],
            borderRadius: "10px",
            borderColor: tan[300],
            "@media (max-width:800px)": {
              fontSize: "10px",
            },
            maxWidth: "300px",
            "&.MuiSelect-select": {
              color: tan[300],
              borderRadius: "10px",
            },
            "&.MuiSelect-nativeInput": {
              color: tan[300],
              backgroundColor: "black",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {},
      },
      MuiDivider: { styleOverrides: { root: { background: orange[200] } } },
      MuiTab: {
        styleOverrides: {
          root: {
            height: "45px",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            letterSpacing: "0.05em",
            "&.Mui-selected": {
              color: tan[300],
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: tan[300],
            "&$disabled": {
              color: orange[100],
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: tan[300],
            "&.Mui-focused": {
              color: tan[300],
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            borderRadius: "10px",
            "&: hover": { borderRadius: "10px" },
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              backgroundColor: "#fff",
              color: tan[300],

              ":disabled": {
                color: tan[300],
              },

              "&:hover fieldset": {
                color: tan[300],
                borderColor: "#DBD2C8",
                borderWidth: "1px",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#DBD2C8",
                borderWidth: "1px",
                color: tan[300],
              },
              "& fieldset": { color: tan[300] },
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: { backgroundColor: "white", height: "140px", width: "100%" },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "16px",
            borderRadius: "100px",
            height: "40px",
            minWidth: "fit-content",
            width: "fit-content",
            boxShadow: "none",
            paddingLeft: "20px",
            paddingRight: "20px",
            textAlign: "center",
            textTransform: "none",
            "&:hover": {
              boxShadow: "none",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          loading: {
            color: tan[300],
          },
        },
      },

      MuiAccordion: {
        styleOverrides: {
          root: {
            borderColor: orange[100],
            borderBottom: 0,
            backgroundColor: orange[15],
            color: tan[300],
            borderRadius: "10px",
            textAlign: "left",
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            borderRadius: "10px",
            color: tan[300],
            fontFamily: "Comfortaa",
            fontSize: "24px",
            fontWeight: 400,
            height: "fit-content",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingRight: "33px",
            paddingLeft: "33px",
            textAlign: "left",
            "@media (max-width:800px)": {
              fontSize: "16px",
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            textAlign: "left",
            borderRadius: "10px",
            color: tan[300],
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 400,
            padding: "33px",
            paddingTop: "10px",
            "@media (max-width:800px)": {
              fontSize: "12px",
            },
          },
        },
      },
    },
    typography: {
      h1: {
        fontFamily: "Comfortaa",
        fontWeight: 400,
        fontSize: "36px",
        "@media (max-width:800px)": {
          fontSize: "18px",
          fontWeight: 600,
        },
      },
      h2: {
        fontFamily: "Comfortaa",
        fontWeight: 400,
        fontSize: "34px",
        "@media (max-width:800px)": {
          fontSize: "18px",
        },
      },
      h3: {
        fontFamily: "Comfortaa",
        fontWeight: 400,
        fontSize: "24px",
        "@media (max-width:800px)": {
          fontSize: "16px",
          lineHeight: "normal",
        },
      },
      h4: {
        fontFamily: "Comfortaa",
        fontSize: "18px",
        fontWeight: 500,
        "@media (max-width:800px)": {
          fontSize: "14px",
          lineHeight: "normal",
        },
      },
      subtitle1: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 600,
        "@media (max-width:800px)": {
          fontSize: "12px",
          lineHeight: "normal",
        },
      },
      subtitle2: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 600,
        "@media (max-width:800px)": {
          fontSize: "14px",
        },
      },
      body1: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        "@media (max-width:800px)": {
          fontSize: "12px",
        },
      },
      body2: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        "@media (max-width:800px)": {
          fontSize: "14px",
        },
      },
      button: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 600,
        borderRadius: "40px",
        whiteSpace: "nowrap",
        "@media (max-width:800px)": {
          fontSize: "14px",
        },
      },
    },
    palette: {
      orange: {
        main: orange[100],
        contrastText: "#fff",
      },
      tan: {
        main: tan[300],
        contrastText: orange[100],
        light: tan[100],
        dark: tan[300],
      },
      yellow: { main: yellow[100], light: yellow[100], dark: yellow[100], contrastText: orange[15] },
      white: { main: "#FFF", light: "#FFF", dark: "#FFF", contrastText: tan[300] },
      primary: { main: orange[100], dark: orange[200], light: orange[70], contrastText: "#fff" },
      mode: "light",
      background: {
        default: grey[100],
      },
      secondary: { main: tan[300], dark: tan[300], contrastText: orange[15] },

      divider: orange[100],
      text: {
        secondary: orange[100],
        primary: tan[300],
        disabled: tan[150],
      },
      warning: { main: "#FD2323" },
    },
  },
  nbNO,
);

lightTheme = responsiveFontSizes(lightTheme);

export function Theme({ children }) {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
