import { Box, ButtonBase, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useRef } from "react";
import { tan } from "../Theme";

interface Props {
  iconSrc: string;
  urlHref: string;
  title: string;
  description?: string;
  observer?: IntersectionObserver;
  className?: string;
}

const IconLinkButton = ({ iconSrc, description, urlHref = "#register", title, observer, className }: Props) => {
  const ref = useRef(null);

  useEffect(() => {
    const { current } = ref;
    if (current) {
      observer?.observe(current);
      return () => {
        observer?.unobserve(current);
      };
    }
  }, [ref]);

  return (
    <a href={urlHref} ref={ref} className={className}>
      <CardWrapper
        sx={{
          border: `1px solid ${tan[50]}`,
          boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          "&:hover": {
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F0ECE7",
            width: "100%",
            height: "123px",
            borderRadius: "10px 10px 0px 0px",
            alignContent: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <IconImage src={iconSrc} />
        </Box>
        <Box sx={{ height: "138px", paddingLeft: "30px", pr: "30px", pt: "10px" }}>
          <TextWrap>
            <Typography variant="subtitle2" color={"primary"}>
              {title}
            </Typography>
            <Typography variant="body2">{description}</Typography>
          </TextWrap>
        </Box>
      </CardWrapper>
    </a>
  );
};

export default IconLinkButton;

const CardWrapper = styled(ButtonBase)`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 210px;
  text-align: left;
`;

const IconImage = styled("img")`
  height: auto;
  padding: 10%;
`;

const TextWrap = styled(Box)`
  display: flex;
  flex-direction: column;
`;
