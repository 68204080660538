import { enqueueSnackbar } from "notistack";
import { ProblemDetails } from "./generated/happydogsSchemas";
import { ClerkAPIError } from "@clerk/types/dist/api";

const isError = (err: unknown): err is Error => {
  return !!err && typeof err === "object" && "message" in err;
};

const isListOfErrors = (err: unknown): err is { errors: Array<unknown> } => {
  return !!err && typeof err === "object" && "errors" in err && Array.isArray(err.errors);
};

const isStr = (err: unknown): err is string => {
  return !!err && typeof err === "string";
};

const isClerkAPIError = (err: unknown): err is ClerkAPIError => {
  return isError(err) && "longMessage" in err;
};

const isProblemErrorsType = (err: unknown): err is ProblemDetails => {
  return !!err && typeof err === "object" && "detail" in err && typeof err.detail == "string";
};

export const HandleErrorWithToast = (error: unknown) => {
  console.warn("ToastError", error);

  if (isStr(error)) {
    enqueueSnackbar(error, { variant: "error", preventDuplicate: true });
    return;
  }

  if (isListOfErrors(error)) {
    return error.errors.forEach(HandleErrorWithToast);
  }

  if (isClerkAPIError(error)) {
    enqueueSnackbar(error.longMessage, { variant: "error", preventDuplicate: true });
    return;
  }

  if (isProblemErrorsType(error)) {
    enqueueSnackbar(error.detail, { variant: "error", preventDuplicate: true });
    return;
  }

  if (!isError(error)) {
    enqueueSnackbar("Ukjent feil", { variant: "error", preventDuplicate: true });
    return;
  }

  enqueueSnackbar(error.message, {
    variant: "error",
    preventDuplicate: true,
  });
};
export const SuccessToast = (message: string) => {
  enqueueSnackbar(message, { variant: "success" });
};

/** @remarks
 * Use HandleMultiplePromisesWithToast by creating an asynchronous generator that yields promises instead
 */
export const HandleAsyncFunctionWithToast = <TArgs extends Array<unknown>, TReturn>(
  fn: (...args: TArgs) => Promise<TReturn | null>,
  successMessage = "Lagret",
) => {
  return async (...args: TArgs) => {
    try {
      const result = await fn(...args);
      SuccessToast(successMessage);
      return result;
    } catch (e) {
      console.log(e);
      HandleErrorWithToast(e);
      return null;
    }
  };
};

export const HandleMultiplePromisesWithToast = <TArgs extends Array<unknown>, TPromise = void | unknown>(
  promises: (...args: TArgs) => Generator<TPromise>,
  refetch?: (fulfilled: boolean, values?: Awaited<TPromise>[]) => void,
  successMessage = "Lagret",
) => {
  return (...args: TArgs) =>
    Promise.all(promises(...args))
      .then((values) => {
        SuccessToast(successMessage);
        if (refetch) refetch(true, values);
      })
      .catch((result) => {
        HandleErrorWithToast(result.detail);
        if (refetch) refetch(false);
      });

  /*
  If we want to display all errors in the toast then we have to do something like this:

  return Promise.allSettled(promises())
    .then((results) => {
      results.forEach((result) => {
        switch (result.status) {
          case "fulfilled": { aaA`
          }
        }
      });
    })
    .catch(HandleErrorWithToast);;*/
};
