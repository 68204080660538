"use client";

import { Box, ButtonBase, Fade, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Type } from "./enum";
import { SearchV2Response } from "./generated/happydogsSchemas";
import { tan } from "./Theme";

interface Props {
  results: SearchV2Response[];
  open: boolean;
  query: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
const SearchResults = ({ results, open, query, setOpen }: Props): JSX.Element => {
  const router = useRouter();
  const [amountofBreeders, setAmountOfBreeders] = useState(0);
  const [amountofLitters, setAmountOfLitters] = useState(0);

  const [amountofDogs, setAmountOfDogs] = useState(0);

  useEffect(() => {
    setAmountOfLitters(results.filter((result) => result?.type.toLowerCase() === Type[Type.Litter].toLowerCase()).length);
    setAmountOfDogs(results.filter((result) => result?.type.toLowerCase() === Type[Type.Dog].toLowerCase()).length);
    setAmountOfBreeders(results.filter((result) => result?.type.toLowerCase() === Type[Type.Breeder].toLowerCase()).length);
  }, [results]);

  return (
    <Fade in={open}>
      <AllResultsWrapper>
        <Box>
          <Typography variant="subtitle1">Forslag for deg</Typography>
          <Box display="flex" flexDirection={"column"} gap="25px" mt={"15px"}>
            {results.slice(0, 3).map((result, index) => (
              <SuggestedList key={index} result={result} setOpen={setOpen} />
            ))}
          </Box>
        </Box>
        <Box sx={{ borderBottom: `1px solid ${tan[50]}` }} />
        <Box>
          <Typography variant="subtitle1">Alle resultater</Typography>
          <Box display="flex" flexDirection={"column"} gap="25px" mt={"15px"}>
            <ResultItem
              link={`/search?type=Litter&query=${query}`}
              imgSrc={"/assets/icons/litterIcon.svg"}
              name={"Valpekull"}
              subtitle={amountofLitters}
              fillImg={false}
              setOpen={setOpen}
            />
            <ResultItem
              link={`/search?type=Breeder&query=${query}`}
              imgSrc={"/assets/icons/breeder.svg"}
              name={"Oppdrettere"}
              subtitle={amountofBreeders}
              fillImg={false}
              setOpen={setOpen}
            />
            <ResultItem
              link={`/search?type=Dog&query=${query}`}
              imgSrc={"/assets/icons/dogface.svg"}
              name={"Hunder"}
              subtitle={amountofDogs}
              fillImg={false}
              setOpen={setOpen}
            />
          </Box>
        </Box>
      </AllResultsWrapper>
    </Fade>
  );
};

export const SuggestedList = ({ result, setOpen }: { result: SearchV2Response; setOpen: Dispatch<SetStateAction<boolean>> }) => {
  switch (result?.type.toLowerCase()) {
    case Type[Type.Breeder].toLowerCase():
      return (
        <ResultItem
          link={`/breeder/${result.breeder?.breederSlug}`}
          imgSrc={result.breeder?.profilePicture?.url ?? "/assets/icons/breeder.svg"}
          name={result.breeder?.kennelName}
          subtitle={"Oppdretter"}
          fillImg={!!result.breeder?.profilePicture?.url}
          setOpen={setOpen}
        />
      );
    case Type[Type.Litter].toLowerCase():
      return (
        <ResultItem
          link={`/breeder/${result.litter?.breederSlug}/${result.litter?.litterSlug}`}
          imgSrc={result.litter?.profilePicture?.url ?? "/assets/icons/litterIcon.svg"}
          name={result.litter?.litterName}
          subtitle={"Kull"}
          fillImg={!!result.litter?.profilePicture?.url}
          setOpen={setOpen}
        />
      );
    case Type[Type.Dog].toLowerCase():
      return (
        <ResultItem
          link={`/dog/${result.dog?.dogSlug}`}
          imgSrc={result.dog?.profilePicture?.url ?? "/assets/icons/dogface.svg"}
          name={result.dog?.registryName}
          subtitle={"Hund"}
          fillImg={!!result.dog?.profilePicture?.url}
          setOpen={setOpen}
        />
      );
    default:
      return <></>;
  }
};

const ResultItem = ({ link, imgSrc, name, subtitle, fillImg, setOpen }) => {
  return (
    <>
      <Link href={link} style={{ textDecoration: "underline" }} onClick={() => setOpen(false)}>
        <Box display={"flex"} alignContent={"center"} alignItems={"center"} gap="20px">
          <Image
            src={imgSrc}
            alt={"Profilbilde for " + name}
            width={50}
            height={40}
            style={{ borderRadius: "10px", objectFit: fillImg ? "cover" : "contain" }}
          />
          <Box display={"flex"}>
            <Typography variant="body1" sx={{ textDecoration: "underline" }}>
              <span style={{ fontWeight: "bold" }}>{name} </span>({subtitle})
            </Typography>
          </Box>
        </Box>
      </Link>
    </>
  );
};

export default SearchResults;

const AllResultsWrapper = styled(Box)`
  display: flex;
  border: 1px solid #c1c1c1;
  flex-direction: column;
  border-radius: 20px;
  padding: 20px;
  background: #fff;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  gap: 20px;
`;

const ResultWrapper = styled(ButtonBase)`
  background-color: #fff;
  display: flex;
  justify-content: start;
  text-align: left;
`;
