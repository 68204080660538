"use client";
import { ExpandCircleDown } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from "@mui/material";
import { useState } from "react";

const FAQ = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Container sx={{ display: "flex", flexDirection: " column", gap: "50px", pt: "100px", pb: "100px" }} id="faq">
      <Typography variant="h2" alignSelf={"center"}>
        Ofte stilte spørsmål
      </Typography>
      <Box sx={{ display: "flex", flexDirection: " column", gap: "25px" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          square
          disableGutters
          sx={{
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandCircleDown color="tan" fontSize="large" />}>Hva er HappyDogs? </AccordionSummary>
          <AccordionDetails>
            HappyDogs tilbyr digitale verktøy som fremmer bærekraftig avl og hundehold. Vi gir deg verdi fra du ønsker å kjøpe deg hund, og
            gjennom hele hundens liv.{" "}
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          disableGutters
          sx={{
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandCircleDown color="tan" fontSize="large" />}>
            Hvilke digitale verktøy tilbyr HappyDogs for valpekjøpere?{" "}
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>Utforsk og sammenlign oppdrettere. Du kan enkelt se hvem som følger Norsk kennelklubb og raseklubbens avlskrav. </li>
              <li>Kontakt oppdrettere direkte på plattformen for å stille dine spørsmål. </li>
              <li>
                Fyll ut standardisert spørreskjema og send til en, eller flere oppdrettere der du ønsker å stå på venteliste for å kjøpe
                valp.
              </li>
              <li>
                Hold oversikt over hvilke oppdrettere du står på venteliste til. Blir du tildelt valp, kan du trekke forespørselen hos
                eventuelle andre oppdrettere.{" "}
              </li>
              <li>
                Når valpen overføres til deg, kan det følge med loggført informasjon om din valp, fra tiden hos oppdretteren din. På den
                måten kan du bygge videre på det gode arbeidet oppdretteren din allerede har startet. Fortsett med loggføring av valpens
                vekt, utvikling, vaksiner, miljøtrening eller for eksempel sosialisering.{" "}
              </li>
              <li>
                Presenter valpen din med egen hundeprofil. Her kan du skrive fritekst, publisere bilder og legge ut helse- og
                aktivitetsresultater.
              </li>
            </ul>{" "}
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          disableGutters
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandCircleDown color="tan" fontSize="large" />}>
            Hvilke digitale verktøy tilbyr HappyDogs for oppdrettere?{" "}
          </AccordionSummary>
          <AccordionDetails>
            {" "}
            <ul>
              <li>
                Lag en brukervennlig oppdretterprofil for å presentere din visjon, hva du ser etter hos en valpekjøper eller for eksempel
                informasjon om din neste kombinasjon. Du kan lage overskrifter og undertekster som du selv ønsker.{" "}
              </li>
              <li>Presentere dine hunder, valpekull og publiser i galleri. </li>
              <li>Andre brukere kan sende deg melding og du kan svare dem direkte på siden.</li>
              <li>
                Motta forespørsler fra potensielle valpekjøpere som ønsker å stå på din venteliste for valp. Du kan godta eller avslå og
                holde oversikt i ditt ventelisteverktøy.{" "}
              </li>
              <li>
                Du kan loggføre informasjon om dine hunder og valper i valpekull. Informasjonen kan sømløst overføres til kommende eier. Du
                kan loggføre løpetid, treninger eller bilder av for eksempel ett sår.
              </li>
            </ul>{" "}
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          disableGutters
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          sx={{
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandCircleDown color="tan" fontSize="large" />}>
            Hvordan oppretter jeg en brukerkonto?{" "}
          </AccordionSummary>
          <AccordionDetails>
            For å opprette en brukerkonto, trykk på “registrer” oppe i høyre hjørne og følg instruksjonene. Du vil bli bedt om å oppgi noen
            grunnleggende personopplysninger og velge et passord.{" "}
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          disableGutters
          sx={{
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandCircleDown color="tan" fontSize="large" />}>
            Hvorfor må jeg verifisere meg med Vipps?{" "}
          </AccordionSummary>
          <AccordionDetails>
            For å bygge tillit mellom brukere og for å unngå opprettelse av falske profiler, kreves det verifisering med Vipps. Du trenger
            bare å verifisere deg én gang.{" "}
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          disableGutters
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          sx={{
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandCircleDown color="tan" fontSize="large" />}>
            Er det noen kostnader knyttet til å bruke HappyDogs?{" "}
          </AccordionSummary>
          <AccordionDetails>
            All funksjonalitet er gratis for øyeblikket. I fremtiden vil det være mulig å oppgradere profilen din til "Premium" som gir deg
            flere fordeler og funksjoner.
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          disableGutters
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          sx={{
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandCircleDown color="tan" fontSize="large" />}>
            Hvordan oppretter jeg til en hundeprofil?{" "}
          </AccordionSummary>
          <AccordionDetails>
            Kun eier av hunden kan registrere hundeprofil. Når du har opprettet en brukerkonto, kan du legge til din(e) hund(er). Her kan du
            skrive fritekst, publisere helseresultater, aktiviteter, jaktresultater, mentaltester osv. Du kan også laste opp bilder.{" "}
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          disableGutters
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
          sx={{
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandCircleDown color="tan" fontSize="large" />}>
            Hvordan oppretter jeg en oppdretterprofil?
          </AccordionSummary>
          <AccordionDetails>
            Du må være verifisert for å opprette oppdretterprofil. Deretter må du huke av for at du er “oppdretter” via “min side”. Trykk
            deretter på “oppdretter” i headeren og følg instruksjonene.
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          disableGutters
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
          sx={{
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandCircleDown color="tan" fontSize="large" />}>
            Er informasjonen jeg legger til på HappyDogs trygg og beskyttet?{" "}
          </AccordionSummary>
          <AccordionDetails>
            Vi tar personvern og datasikkerhet på alvor. All informasjon som deles på plattformen vår, er beskyttet i samsvar med gjeldende
            lover og forskrifter om personvern. Se vår personvernerklæring for å lese mer.{" "}
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel10"}
          onChange={handleChange("panel10")}
          disableGutters
          sx={{
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandCircleDown color="tan" fontSize="large" />}>
            Har du problemer, forbedringsforslag eller spørsmål?
          </AccordionSummary>
          <AccordionDetails>
            Du kan benytte vår supportknapp, som du finner i menyen under ditt profilbilde i øverste høyre hjørne, eller sende e-post til
            support@happydogs.no. Vi vil gjøre vårt ytterste for å hjelpe deg så raskt som mulig.
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          disableGutters
          expanded={expanded === "panel11"}
          onChange={handleChange("panel11")}
          sx={{
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandCircleDown color="tan" fontSize="large" />}>
            Er det noen aldersbegrensninger for å bruke HappyDogs?
          </AccordionSummary>
          <AccordionDetails>Ja, for å bruke HappyDogs-tjenestene må du være over 18 år. </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
};

export default FAQ;
