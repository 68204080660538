"use client";
import { AppBar, Autocomplete, Box, Container, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { orange, tan } from "../components/Theme";
import landingPage from "../public/images/landingPage.png";

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
  textOverflow: "ellipsis",
});

export const ColumnWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;

  text-overflow: ellipsis;
`;

//Navigationbar

export const ContentWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  gap: 50px;
  padding-bottom: 50px;
  @media screen and (max-width: 600px) {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    padding-top: 20px;
    gap: 30px;
  }
`;

export const MenuItemWrapper = styled(Box)`
  text-align: center;
  background: #1c5c54;
  width: 133px;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledAppBar = styled(AppBar)`
  background-color: ${orange[15]};
  box-shadow: none;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: auto;
  @media (max-width: 1100px) {
    padding-left: 20px;
  }
`;

export const AppBarActions = styled(Box)`
  justify-content: space-between;
  display: flex;
  align-items: start;
  gap: 30px;
  width: 100%;
  padding-top: 20px;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const MobileActions = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  gap: 15px;
  align-items: center;
  @media (min-width: 900px) {
    margin-right: 30px;
    display: none;
  }
`;

//Landingpage registration

export const ImageWrapper = styled(Box)<{}>`
  display: flex;
  height: 70vh;
  max-width: 100%;
  background-image: url(${landingPage.src});
  background-size: cover;
  background-position: center center;
  align-items: end;
  justify-content: center;
`;

export const HeaderImage = styled(ImageWrapper)`
  height: 35vh;
  background-position: 25% 15%;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const BoxWrapper = styled(Box)`
  display: flex;
  gap: 100px;
  align-items: center;
  padding-top: 50px;
  justify-content: center;
  text-align: center;
  background: ${orange[15]};
  padding-bottom: 50px;

  @media (max-width: 800px) {
    padding: 30px;
    padding-top: 40px;
  }
`;

export const AccountCreation = styled(Box)`
  padding-top: 39px;
  font-weight: 700;
  font-size: 21px;
  line-height: 23px;
  margin-bottom: 30px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const InputFieldWrapper = styled(TextField)`
  && {
    width: 550px;
    margin-bottom: 20px;

    @media (max-width: 800px) {
      width: 100%;
    }
  }
`;

export const SelectTextfield = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    border: 0,
    borderBottom: `1px solid ${orange[100]}`,
    borderRadius: "20px 20px 0 0",
    padding: "10px",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "& .MuiFormLabel-root": {
    fontSize: "16px", // or black
  },
}));

export const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
    fontSize: 16,
  },
  "& .MuiAutocomplete-popper, & .MuiAutocomplete-paper": {
    borderRadius: "20px !important",
  },
  "& .MuiAutocomplete-paper": {
    borderRadius: "20px !important",
  },
});

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "&: hover": { boxShadow: "none", borderRadius: "10px" },

  "& .MuiOutlinedInput-root": {
    backgroundColor: orange[15],
    color: tan[300],
    boxShadow: "none",
  },
  "&:hover fieldset": {
    color: tan[300],
  },
  "&.Mui-focused fieldset": {
    color: tan[300],
  },
  "& fieldset": { color: tan[300] },
}));

//Footer components

export const CopyrightText = styled("span")`
  font-weight: 700;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.4);
`;

export const FooterWrapper = styled(Box)`
  justify-self: flex-end;
  background-color: ${tan[300]};
  width: 100%;

  padding-top: 50px;
  padding-bottom: 30px;
  @media (max-width: 800px) {
    padding-bottom: 72px;
    padding-left: 52px;
  }
  color: #fff;
`;
