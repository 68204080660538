"use client";

import SearchIcon from "@mui/icons-material/Search";
import { Popper } from "@mui/material";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { useDebounce } from "@uidotdev/usehooks";
import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";
import SearchResults from "./SearchResults";
import { useSearchV2Endpoint } from "./generated/happydogsComponents";

const Search = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "10px",

  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000,
  [theme.breakpoints.down("md")]: {
    marginTop: "5px",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "primary",
  paddingLeft: "10px",

  "& .MuiInputBase-input": {
    backgroundColor: "white",

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    boxShadow: "0px 4px 8px 0px rgba(246, 154, 71, 0.10)",
    transition: theme.transitions.create("width"),

    border: "1px solid #C1C1C1",
    height: "45px",
    borderRadius: "50px",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
      height: "35px",
    },
  },
}));

const StyledSearchBarLanding = styled(InputBase)(({ theme }) => ({
  color: "primary",

  "& .MuiInputBase-input": {
    backgroundColor: "white",

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(5)})`,
    boxShadow: "0px 4px 8px 0px rgba(246, 154, 71, 0.10)",
    transition: theme.transitions.create("width"),
    border: "1px solid #C1C1C1",
    height: "50px",
    width: "100%",
    marginLeft: "0px",
    borderRadius: "10px",
  },
}));

const SearchBar = ({
  focus,
  variant = "Navbar",
  size = "medium",
}: {
  focus: boolean;
  variant?: "Navbar" | "Landingpage";
  size?: "small" | "medium";
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | HTMLTextAreaElement | null>(null);
  const [open, setOpen] = useState(false);

  function handleClick(currentTarget: HTMLInputElement | HTMLTextAreaElement) {
    if (anchorEl !== currentTarget) {
      setAnchorEl(currentTarget);
    }
  }

  const [query, setQuery] = useState("");

  const debouncedQuery = useDebounce(query, 300);
  const { data: resultArray } = useSearchV2Endpoint(
    {
      queryParams: {
        query: debouncedQuery,
        includeBreeders: true,
        includeDogs: true,
        includeLitters: true,
      },
    },
    { enabled: !!debouncedQuery },
  );

  const popperOpen = !!debouncedQuery;

  const handleChange = (e) => {
    handleClick(e.currentTarget);
    setQuery(e.target.value);
    if (pathname === "/search") {
      router.replace(`${pathname}?query=${e.target.value}`);
    }
    handleOpen(true);
  };

  const handleOpen = (boolean) => {
    setOpen(boolean);
  };

  return (
    <Box
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
        router.push(`/search?query=${query}`);
      }}
      width={"100%"}
    >
      <Search>
        <SearchIconWrapper>
          <SearchIcon color="secondary" fontSize="large" />
        </SearchIconWrapper>

        {variant === "Navbar" ? (
          <StyledInputBase
            fullWidth
            placeholder="Hva ser du etter?"
            value={query}
            size="small"
            onChange={handleChange}
            inputRef={(input) => focus === true && input?.focus()}
          />
        ) : (
          <StyledSearchBarLanding
            fullWidth
            placeholder="Hva ser du etter?"
            value={query}
            onChange={handleChange}
            inputRef={(input) => focus === true && input?.focus()}
            onBlur={() => setOpen(false)}
          />
        )}
        <Popper anchorEl={anchorEl} placement={"bottom"} open={popperOpen} sx={{ width: anchorEl?.clientWidth ?? 0, zIndex: 3000 }}>
          {resultArray && <SearchResults setOpen={setOpen} open={!!resultArray && open} results={resultArray} query={debouncedQuery} />}
        </Popper>
      </Search>
    </Box>
  );
};

export default SearchBar;
