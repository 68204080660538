"use client";
import { Box, Button, ButtonBase, Container, Grid, Typography } from "@mui/material";
import Link from "next/link";
import { useState } from "react";
import { orange } from "../Theme";
import { ArticlesView } from "../generated/happydogsSchemas";
interface ArticleProps {
  article: ArticlesView;
  isAdmin?: boolean;
}

export const ArticleThumbnail = ({ article, isAdmin = false }: ArticleProps) => {
  const strippedHtml = article.content.replace(/<[^>]+>/g, "");
  const text = strippedHtml.replace(/&nbsp;/g, " ");

  const sources = article.content.match(/<img [^>]*src="[^"]*"[^>]*>/gm)?.map((x) => x.replace(/.*src="([^"]*)".*/, "$1"));

  return (
    <ButtonBase
      href={isAdmin ? `/admin/articles/${article.articleSlug}` : `/articles/${article.articleSlug}`}
      sx={{
        borderRadius: "10px",
        background: "white",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        width: "473px",
        height: "549px",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        textAlign: "left",
        paddingBottom: "20px",
      }}
      LinkComponent={Link}
    >
      <img
        alt={"Article cover image"}
        src={sources ? sources[0] : "/images/landingPage.png"}
        height={"50%"}
        width={"100%"}
        style={{ objectFit: "cover", objectPosition: "center", alignSelf: "start" }}
      />
      <Box sx={{ width: "100%", height: "45%", padding: "30px", display: "flex", flexDirection: "column", gap: "30px" }}>
        <Typography variant="h3">{article.title}</Typography>
        <Typography variant="body1" overflow={"hidden"}>
          {text}
        </Typography>
      </Box>{" "}
      <Typography
        color={"secondary"}
        sx={{
          textDecoration: "underline",
          paddingRight: "30px",
          alignSelf: "end",
          ":hover": {
            textDecoration: "none",
          },
        }}
        variant="subtitle2"
      >
        {isAdmin ? "Endre" : "Les mer"}
      </Typography>
    </ButtonBase>
  );
};

interface SectionProps {
  articles: ArticlesView[];
}

const ArticlesSection = ({ articles }: SectionProps) => {
  const [amountOfArticles, setAmountOfArticles] = useState(2);
  const sortedArticles = articles.sort((a, b) => new Date(b.postedAt).getTime() - new Date(a.postedAt).getTime());
  const latestArticles = sortedArticles.slice(0, amountOfArticles);

  return (
    <Box sx={{ backgroundColor: orange[15], pt: "100px", pb: "100px", width: "100%" }}>
      <Container>
        <Typography variant="h2" textAlign={"center"} mb={"50px"}>
          Artikler
        </Typography>
        <Grid container justifyContent={"space-evenly"} rowGap={"50px"}>
          {latestArticles.map((article, i) => (
            <ArticleThumbnail key={article.id} article={article} />
          ))}
        </Grid>
        <Box width={"100%"} justifyContent={"center"} display={"flex"} mt={"100px"}>
          {amountOfArticles < 6 ? (
            <Button variant="contained" sx={{ alignSelf: "center" }} onClick={() => setAmountOfArticles(6)} LinkComponent={Link}>
              Se flere
            </Button>
          ) : (
            <Button variant="contained" sx={{ alignSelf: "center" }} href="/articles" LinkComponent={Link}>
              Se alle artikler
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ArticlesSection;
