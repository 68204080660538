"use client";
import { Box, Button, Typography } from "@mui/material";
import Link from "next/link";
import { orange } from "../Theme";
import Image from "next/image";

const AboutUs = () => {
  return (
    <Box
      sx={{
        backgroundColor: orange[15],
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box display={{ xs: "none", md: "block" }} width={"100%"} height="100%">
        <Image
          src="/images/team.jpeg"
          style={{ width: "100%", height: "100%" }}
          sizes="100vw"
          width={0}
          height={0}
          alt={"Bilde av teamet"}
        />
      </Box>
      <Box
        width={"100%"}
        ml={{ md: "100px", xs: "20px" }}
        mr={{ md: "100px", xs: "20px" }}
        display={"flex"}
        flexDirection={"column"}
        gap="30px"
        mb={"40px"}
      >
        <Typography variant="h2" mt={{ md: "20%", xs: "30px" }}>
          Hvem er vi?
        </Typography>
        <Box maxWidth={"90%"}>
          <Typography paragraph>
            HappyDogs er grunnlagt av seks dedikerte hundeelskere. Vi har erfaring innen avl, agility, utstilling, trekk, rallylydighet,
            nosework, som instruktør av ulike hundekurs, styreverv i raseklubb og i den lokale hundeklubben.
          </Typography>
          <Typography paragraph>
            Teamet ønsker å knytte sammen hele økosystemet rundt hundehold, og jobber for å lage digitale verktøy som fremmer god
            hundevelferd. Vi ønsker å være din digitale ressurs fra du kjøper valp, og gjennom hele hundens liv.
          </Typography>
        </Box>
        <Button variant="contained" href="/information/aboutus" sx={{ display: { md: "block", xs: "none" } }} LinkComponent={Link}>
          Les mer
        </Button>
      </Box>
      <Box display={{ xs: "block", md: "none" }} width={"100%"} height="100%">
        <Image
          src="/images/team.jpeg"
          sizes="100vw"
          width={0}
          height={0}
          style={{ width: "100%", height: "auto" }}
          alt={"Bilde av teamet"}
        />
      </Box>
      <Button
        variant="contained"
        href="/information/aboutus"
        sx={{ display: { md: "none", xs: "block" }, mb: "40px", mt: "30px", alignSelf: "center" }}
      >
        Les mer
      </Button>
    </Box>
  );
};

export default AboutUs;
